<template>
  <div class="job-title">
    <div v-if="jobId"
         :class="[jobStatusObj.class]"
         class="job-title__header">
      <div class="job-title__title">{{ jobTitle }}
        <p v-if="jobStatus == 'cancelled'"
           class="job-title__cancel-reason">{{ $gettext("Reason") }}: {{ cancelReasonMessage }}
        </p>
        <p v-if="cancelledReasonKey == 'dangerous_fields_edited' && copiedTo"
           v-html="getJobLink(copiedTo)"></p>
      </div>
      <invite-system v-if="showInviteSelect"
                     class="job-title__header-select" />
    </div>
    <div v-if="storeJobObj.bookingMechanism === 'price_check' && userIsNotInterpreter"
         class="draft-invoice__block">
      <invoice-draft-info :hide-tips="true"
                          :hide-groups="true"
                          :invoice="getPricingData.invoice"
                          class="chat-apply__draft-invoice" />

    </div>
    <slot class="job-title__content"></slot>
  </div>
</template>

<script>
  import helpers from '@/helpers';
  import {mapState, mapGetters} from 'vuex';
  import InvoiceDraftInfo
    from '@/components/assignment_components/one_assignment/interactive_info/shared_components/InvoiceDraftInfo';
  import InviteSystem
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/FAIAdminInviteSystem';

  export default {
    components: {
      'invoice-draft-info': InvoiceDraftInfo,
      'invite-system': InviteSystem
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        storeJobObj: ({storeJobObj}) => storeJobObj || {}
      }),
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsInterpreter'
      ]),
      ...mapGetters('AssignmentFlowStore', [
        'getPricingData'
      ]),
      jobId() {
        return this.storeJobObj.id || '';
      },
      jobStatus() {
        return this.storeJobObj.status || '';
      },
      jobLocked() {
        return this.storeJobObj.locked;
      },
      jobStatusObj() {
        return helpers.getJobInfo.titleJobStatusObj({
          context: this,
          jobStatus: this.jobStatus,
          cancellerName: this.cancellerName,
          cancelledAt: this.cancelledAt,
          jobLocked: this.locked,
          bookingMechanism: this.storeJobObj.bookingMechanism
        }) || {};
      },
      jobStatusText() {
        if (this.jobStatusObj.text) {
          return this.jobStatusObj.text;
        }
        return '';
      },
      jobTitle() {
        return `#${helpers.getJobInfo.jobId(this.storeJobObj)} - ${this.jobStatusText}`;
      },
      cancellerName() {
        return this.$store.state.OneAssignmentStore.storeJobObj?.cancellerName || '';
      },
      cancelledReasonKey() {
        return this.$store.state.OneAssignmentStore.storeJobObj?.cancelledReason || '';
      },
      cancelReasonMessage() {
        const reasons = [
          {key: 'dont_need_interpreter_anymore', message: this.$gettext('I don\'t need an interpreter anymore')},
          {key: 'need_to_rebook_session', message: this.$gettext('I need to rebook this session')},
          {key: 'interpreter_charging_too_much', message: this.$gettext('The interpreter is charging too much.')},
          {key: 'found_interpreter_from_another_provider', message: this.$gettext('I have found an interpreter from another provider')},
          {key: 'solved_problem_without_interpreter', message: this.$gettext('I have solved my problem without an interpreter')},
          {key: 'interpreter_cancelled', message: this.$gettext('The interpreter cancelled and I am no longer interested in hiring for this assignment')},
          {key: 'interpreters_busy', message: this.$gettext('All our interpreters are busy')},
          {key: 'no_person_attending', message: this.$gettext('None of our interpreters are able to attend in-person. May we change this in-person assignment to phone?')},
          {key: 'withdrawn', message: this.$gettext('The interpreter that was assigned has withdrawn. May we change this in-person assignment to phone?')},
          {key: 'not_shown', message: this.$gettext('Interpreter is not shown. We are looking for replacement')},
          {key: 'admin_other', message: this.$gettext('Admin other')},
          {key: 'direct_said_no', message: this.userIsInterpreter ? this.$gettext('You declined the assignment') : this.$gettext('Direct interpreter declined offer')},
          {key: 'dangerous_fields_edited', message: this.$gettext('This assignment was modified, and resulted in a new assignment ') + this.isNumber + this.copiedTo},
          {key: 'direct_not_responded', message: this.$gettext('Specific interpreter did not respond to the invitation')},
          {key: 'other', message: this.$gettext('Other')}
        ];
        const reason = reasons.find((reason) => reason.key === this.cancelledReasonKey) || this.cancelledReasonKey;
        return reason.message || reason;
      },
      cancelledAt() {
        return this.$moment(this.$store.state.OneAssignmentStore.storeJobObj?.cancelledAt).format('DD.MM.YYYY HH:mm') || '';
      },
      copiedTo() {
        if (this.storeJobObj.copiesInfo.length) {
          return this.storeJobObj?.copiesInfo[0]?.id;
        }
        return '';
      },
      isNumber() {
        return this.copiedTo ? 'number ' : '';
      },
      clickHereText() {
        return this.$gettext('Click here to see the updated assignment.');
      },
      isPublishedJob() { return this.jobStatus === 'published'; },
      showInviteSelect() {
        return this.userIsNotInterpreter
          && (this.$isGodmode() && this.isPublishedJob);
      },
    },
    methods: {
      getJobLink(jobId) {
        const routeName = this.userIsNotInterpreter ? 'BuyerOneAssignment' : 'ServerOneAssignment';
        const link = jobId && this.$router.resolve(this.$makeRoute({name: routeName, params: {id: jobId}})).href;
        return `<a class="sk-link" target="_blank" href="${link}">${this.clickHereText}</a>`;
      },
    }
  };
</script>

<style scoped>
.job-title__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px 30px;
}

/* TODO: it will be updated in next stage */
#app.salita-facelifted .job-title__header {
  border-radius: 8px;
}

.chat-apply__draft-invoice {
  margin: 10px 0;
}

.draft-invoice__block {
  margin-bottom: 10px;
  padding: 20px;
  background: white;
}

.job-title__header.assignment__dark-blue-status {
  background-color: #99afbb;
  color: #003855;
}

.job-title__header.assignment__yellow-status {
  background-color: #ffe1c4;
  color: #f93;
}

.job-title__header.assignment__green-status {
  background-color: #b2dadc;
  color: #008489;
}

.job-title__header.assignment__purple-status {
  background-color: #bfbece;
  color: #2b255c;
}

.job-title__header.assignment__red-status {
  background-color: #ffccda;
  color: #f04;
}

.job-title__title {
  font-weight: bold;
  font-size: 18px;
  line-height: 1.11;
}

.job-title__content {
  margin-top: 0;
  padding: 0 10px;
}

.job-title__cancelled-wrapper {
  padding: 5px 0;
}

.job-title__cancel-log {
  color: #000;
  font-weight: normal;
  font-size: 14px;
}

.job-title__header-select {
  margin-left: 20px;
  font-weight: normal;
  font-size: 14px;
}
</style>
